<template>
  <div class="swiper-carrousel">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in carrouselData" :key="index">
        <div class="carrousel__item">
          <img :src="item" alt="" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="mask"></div>
    <div class="swiper-pagination" slot="pagination"></div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';

import Vue from 'vue';
import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay
} from 'swiper/swiper.esm';

import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Mousewheel, Autoplay]);
Vue.use(getAwesomeSwiper(SwiperClass));
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: 'Carrousel',
  components: {
    Swiper,
    SwiperSlide
  },

  props: {
    carrouselData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.swiper.slideTo(3, 1000, false);
    });
  }
};
</script>
