<template>
<div>
  <div class="main">
    <Carrousel :carrouselData="carrouselData" />
    <AppDetail :appData="appDetail" />
  </div>
  <div class="Contact_us">
    <p class="title">Contact Us</p>
    <p class="Email">Email: XinhuaNews_Support@xhsxmt.com</p>
    <p class="Email"> Address: New Media Center, Xinhua News Agency, 57 Xuanwumen Xidajie, Beijing, 100803</p>
    <p class="Copyright">
      <span>Copyright © Xinhua News Agency. </span>
      <span class="bottom">ICP Registration Number:13036228</span>
      <a href="https://xhnews-static.xinhuaxmt.com/privacypolicy/google/index.html">Privacy Policy</a>
       | <a href="https://xhnewsapi.xinhuaxmt.com/v100/termsOfService.html">Term of Services</a></p>
  </div>
</div>
</template>

<script>
import Carrousel from '@/components/Carrousel.vue';
import AppDetail from '@/components/Detail.vue';

export default {
  name: 'XinHuaNews',
  components: {
    Carrousel,
    AppDetail
  },
  data() {
    return {
      carrouselData: [
        require('@/assets/imgs/2-1.png'),
        require('@/assets/imgs/2-2.png'),
        require('@/assets/imgs/2-3.png'),
        require('@/assets/imgs/2-4.png'),
        require('@/assets/imgs/2-5.png'),
        require('@/assets/imgs/2-6.png')
      ],
      appDetail: {
        logo: require('@/assets/imgs/logo-2.png'),
        title: 'Xinhua News',
        slogan: '中国国家通讯社的英文移动门户',
        introduce: [
          '新华社英文客户端是中国国家通讯社的英文移动门户和了解中国的重要窗口。作为新华社全媒体传播体系的一员，新华社英文客户端肩负着“联接中外、沟通世界”的使命，依托新华社遍布全球的新闻资源和采编网络，面向全世界英文读者全天候发布新闻资讯，讲述中国的故事，传播全球新闻。',
          '新华社英文客户端有几大特色：智能水平更出众、二是原创内容更丰富、三是在线生产“活”的新闻、四是服务用户更精准、五是中国特色更鲜明。'
        ],
        download_ios:
          'https://apps.apple.com/cn/app/xin-hua-she-fa-bu/id1291745928?l=en',
        download_android:
          'https://a.app.qq.com/dom/micro/open.jsp?pkgname=com.xinhuamm.xinhuanews'
      }
    };
  }
};
</script>
