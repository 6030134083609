import Vue from 'vue';
import VueRouter from 'vue-router';
import XinHuaClient from '../views/XinHuaClient.vue';
import XinHuaNews from '../views/XinHuaNews.vue';
import LiveCloud from '../views/LiveCloud.vue';
import LiveCloudCompany from '../views/LiveCloudCompany.vue';
// import InChina from '../views/InChina.vue';
import { isMobile } from '@/utils/utils';

var clientName = isMobile() ? 'mobile' : 'pc';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'XinHuaClient',
    component: XinHuaClient,
    meta: {
      title: '新华社客户端'
    }
  },
  {
    path: '/xinhua-news',
    name: 'XinHuaNews',
    component: XinHuaNews,
    meta: {
      title: 'Xinhua News'
    }
  },
  {
    path: '/live-cloud',
    name: 'LiveCloud',
    component: LiveCloud,
    meta: {
      title: '现场云'
    }
  },
  {
    path: '/live-cloud-company',
    name: 'LiveCloudCompany',
    component: LiveCloudCompany,
    meta: {
      title: '现场云企业版'
    }
  }
  // {
  //   path: '/in-china',
  //   name: 'InChina',
  //   component: InChina,
  //   meta: {
  //     title: '我在中国'
  //   }
  // }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {
  window.document.title = `${to.meta.title} - 新华社新媒体中心`;
  document.getElementsByTagName(
    'body'
  )[0].className = `${to.name}-${clientName}`;

  next();
});

export default router;
