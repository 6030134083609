<template>
  <div class="app-detail">
    <div class="app-detail__hd">
      <div class="app-detail__logo">
        <img :src="appData.logo" alt="" />
      </div>
      <h2 class="app-detail__title">{{ appData.title }}</h2>
    </div>
    <p v-if="appData.slogan" class="app-detail__slogan">
      {{ appData.slogan }}
    </p>
    <div class="app-detail__bd">
      <p
        class="app-detail__introduce"
        v-for="(item, index) in appData.introduce"
        :key="index"
      >
        {{ item }}
      </p>
    </div>

    <div class="app-detail__download">
      <a
        class="app-detail__btn"
        v-if="appData.download_ios"
        :href="appData.download_ios"
        target="_blank"
      >
        <i class="icon-ios"></i>
        <span class="app-detail__btn-txt">IOS 安装</span>
      </a>
      <a
        class="app-detail__btn"
        v-if="appData.download_android"
        :href="appData.download_android"
        target="_blank"
      >
        <i class="icon-android"></i>
        <span class="app-detail__btn-txt">Android 安装</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDetail',
  props: {
    appData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>
