<template>
  <div
    id="app"
    class="app"
    :class="[isMobile() ? 'client-mobile' : 'client-pc']"
  >
    <div class="header">
      <div class="logo">新华社新媒体中心</div>

      <span v-if="showCurrentApp" class="current-app" @click="triggleMenu">
        {{ currentAppName }}
      </span>

      <div v-if="menuVisible" class="nav">
        <router-link
          v-for="(item, index) in routerLinks"
          :to="item.linkTo"
          :key="index"
          @click.native="changeApp(item)"
        >
          {{ item.appName }}
        </router-link>
      </div>
    </div>
    <router-view />
    <div class="mask-cover" v-if="maskVisible" @click="closeMenu"></div>
  </div>
</template>

<script>
import { isMobile } from '@/utils/utils';

export default {
  data() {
    return {
      showCurrentApp: false,
      menuVisible: false,
      maskVisible: false,
      currentAppName: '新华社客户端',
      routerLinks: [
        {
          linkTo: '/',
          appName: '新华社客户端'
        },
        {
          linkTo: '/xinhua-news',
          appName: 'Xinhua News'
        },
        {
          linkTo: '/live-cloud',
          appName: '现场云'
        },
        {
          linkTo: '/live-cloud-company',
          appName: '现场云企业版'
        }
        // {
        //   linkTo: '/in-china',
        //   appName: '我在中国'
        // }
      ]
    };
  },
  created() {
    if (isMobile()) {
      this.showCurrentApp = true;
    } else {
      this.menuVisible = true;
    }
  },
  methods: {
    changeApp(item) {
      if (isMobile()) {
        this.menuVisible = !this.menuVisible;
        this.maskVisible = !this.maskVisible;
        this.currentAppName = item.appName;
      }
    },
    triggleMenu() {
      this.menuVisible = !this.menuVisible;
      this.maskVisible = !this.maskVisible;
    },
    isMobile,
    closeMenu() {
      this.menuVisible = !this.menuVisible;
      this.maskVisible = false;
    }
  }
};
</script>

<style lang="scss">
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  transition: background-image 0.25s ease-in;
}
</style>
