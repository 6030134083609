<template>
  <div class="main">
    <Carrousel :carrouselData="carrouselData" />
    <AppDetail :appData="AppDetail" />
  </div>
</template>

<script>
import Carrousel from '@/components/Carrousel.vue';
import AppDetail from '@/components/Detail.vue';

export default {
  name: 'LiveCloudCompany',
  components: {
    Carrousel,
    AppDetail
  },
  data() {
    return {
      carrouselData: [
        require('@/assets/imgs/4-1.png'),
        require('@/assets/imgs/4-2.png'),
        require('@/assets/imgs/4-3.png'),
        require('@/assets/imgs/4-4.png'),
        require('@/assets/imgs/4-5.png')
      ],
      AppDetail: {
        logo: require('@/assets/imgs/logo-4.png'),
        title: '现场云企业版',
        slogan: '生态级企业资讯移动化生产传播平台',
        introduce: [
          '新华社“现场云企业版”全国服务平台,是新华社落实中央要求，推进企业融合、创新企业服务的战略性举措，其旨在与企业和党政机关共享成熟的“现场新闻”技术服务体系和先进的移动化全企业直播工具，帮助入驻企业快速跨入移动互联网时代。截至2020年3月底，已有包含中央企业、地方企业、党政机关在内的逾3800家企业入驻该平台。'
        ],
        download_ios: '',
        download_android:
          'http://app.mi.com/details?id=com.ucap.xcycompanies&ref=search'
      }
    };
  }
};
</script>
