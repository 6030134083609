<template>
  <div class="main">
    <Carrousel :carrouselData="carrouselData" />
    <AppDetail :appData="AppDetail" />
  </div>
</template>

<script>
import Carrousel from '@/components/Carrousel.vue';
import AppDetail from '@/components/Detail.vue';

export default {
  name: 'LiveCloud',
  components: {
    Carrousel,
    AppDetail
  },
  data() {
    return {
      carrouselData: [
        require('@/assets/imgs/3-1.png'),
        require('@/assets/imgs/3-2.png'),
        require('@/assets/imgs/3-3.png'),
        require('@/assets/imgs/3-4.png'),
        require('@/assets/imgs/3-5.png')
      ],
      AppDetail: {
        logo: require('@/assets/imgs/logo-3.png'),
        title: '现场云',
        slogan: '全国最大的新闻内容在线生产平台',
        introduce: [
          '新华社“现场云”全国服务平台,是新华社落实中央要求，推进媒体融合、创新媒体服务的战略性举措，其旨在与媒体和党政机关共享成熟的“现场新闻”技术服务体系和先进的移动化全媒体直播工具，帮助入驻机构快速跨入移动互联网时代。截至2020年10月初，已有包含中央媒体、地方媒体、党政机关在内的近4000家机构入驻该平台。'
        ],
        download_ios:
          'https://a.app.qq.com/o/simple.jsp?pkgname=com.xinhuamm.xianchangyun',
        download_android:
          'https://android.myapp.com/myapp/detail.htm?apkName=com.xinhuamm.xianchangyun&ADTAG=mobile'
      }
    };
  }
};
</script>
