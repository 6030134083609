<template>
  <div class="main">
    <Carrousel :carrouselData="carrouselData" />
    <AppDetail :appData="AppDetail" />
  </div>
</template>

<script>
import Carrousel from '@/components/Carrousel.vue';
import AppDetail from '@/components/Detail.vue';

export default {
  name: 'XinHuaClient',
  components: {
    Carrousel,
    AppDetail
  },
  data() {
    return {
      carrouselData: [
        require('@/assets/imgs/1-1.png'),
        require('@/assets/imgs/1-2.png'),
        require('@/assets/imgs/1-3.png'),
        require('@/assets/imgs/1-4.png'),
        require('@/assets/imgs/1-5.png')
      ],
      AppDetail: {
        logo: require('@/assets/imgs/logo-1.png'),
        title: '新华社',
        slogan: '新闻现场 就在你掌上',
        introduce: [
          '新华社客户端是国家通讯社移动门户和新媒体旗舰、中国新媒体国家队领军者、中国最大党政客户端集群统一入口和综合信息服务统一平台。',
          '新华社客户端依托国家通讯社遍布全球的新闻信息采集网络，全天候发布一手新闻资讯，提供政务信息、便民应用、文化娱乐、生活休闲等服务，推动移动政务，服务社会民众。'
        ],
        download_ios: 'https://d.xinhuaxmt.com',
        download_android:
          'https://a.app.qq.com/o/simple.jsp?pkgname=net.xinhuamm.mainclient'
      }
    };
  }
};
</script>
